/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License"),
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

// export default () => {
// console.log(
//   "🚀 ~ file: import-components.publish.tsx ~ line 18 ~ default getting imports"
// );
// return Promise.all([
import "component-rh-divider/aem";
// import  "component-rh-logo"
// import  "component-rh-spinner"
import "component-link/aem";
import "component-box/aem";
import "component-button/aem";
import "component-accordion/aem";
import "component-text/aem";
import "component-text-custom/aem";
import "container-header-switcher/aem";
import "component-html-head-tags/aem";
import "component-rh-image/aem";
import "component-rh-typography/aem";
import "component-rh-carousel/aem";
import "component-rh-request-consultation/aem";
import "component-rh-modal-trigger/aem";
import "component-rh-modal/aem";
import "component-rh-submenu/aem";
import "component-rh-quicklinks/aem";
import "component-rh-anchor/aem";
// import  "container-search-field/aem"
// import  "icon-aem"
// import  "page-collection-gallery/aem"
import "component-promo/SharedPromos/PromoVideo/Wistia/aem";
import "component-rh-wistia-hero/aem";
import "component-rh-aem-slide/aem";
import "component-rh-dm-smart-crop-image/aem";
import "component-rh-dm-smart-crop-image-v2/aem";
import "component-rh-positioning-container/aem";
import "component-rh-geoexperienceloader/aem";
import "component-rh-geopromoloaderatg/aem";
import "@RHCommerceDev/rh-global-header/aem";
import "@RHCommerceDev/component-media-player/aem";
import "component-form-container/aem";
import "component-collection-carousel/aem";
import "component-form-text/aem";
import "component-form-option/aem";
import "component-form-button/aem";
import "component-salesforce-chat/aem";
import "component-rh-dmvideo/aem";
import "@RHCommerceDev/component-rh-scroll-up-button/aem";
import "@RHCommerceDev/component-menu-link-list/aem";
import "@RHCommerceDev/component-drop-down-flyout-menu/aem";
import "@RHCommerceDev/component-box2/aem";
import "dialog-custom-window/aem";
import "@RHCommerceDev/component-rh-restaurants-main/aem";
import "@RHCommerceDev/component-rh-restaurants-v2/aem";
import "@RHCommerceDev/component-text-custom2/aem";
import "@RHCommerceDev/component-text-custom3/aem";
import "@RHCommerceDev/component-rhr-carousel/aem";
import "@RHCommerceDev/component-virtual-agent/aem";
import "@RHCommerceDev/component-rh-button-add-membership/aem";
import "@RHCommerceDev/component-static-json-provider/aem";
import "@RHCommerceDev/component-rh-video-multiple-links/aem";
import "dialog-trade-request/aem";
import "page-customer-service/ReturnPolicy/OrderDetailsForm/aem";
import "@RHCommerceDev/component-chat-trigger/aem";
import "component-rh-alert/aem";
import "@RHCommerceDev/container-animation-scroll-trigger/aem";
import "@RHCommerceDev/component-sticky/aem";
import "@RHCommerceDev/component-rh-html/aem";
import "component-rh-spa-include/aem";
import "@RHCommerceDev/component-rh-animation-timelines/aem";
import "@RHCommerceDev/component-rhr-quadrant-slideshow/aem";
import "@RHCommerceDev/component-rh-guest-house/aem";
import "@RHCommerceDev/container-ghnav/aem";
import "@RHCommerceDev/icon-aem/aem";
import "@RHCommerceDev/component-async-content/aem";
import "@RHCommerceDev/component-link2/aem";
import "container-footer/aem";
import "@RHCommerceDev/container-visual-site-map/aem";
import "@RHCommerceDev/component-rh-opentable-reservation/aem";
import "@RHCommerceDev/component-rh-simple-image/aem";
import "@RHCommerceDev/component-intl-email-subscription-form/aem";
import "@RHCommerceDev/component-intl-update-email-subscription-form/aem";
