import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AEMContainerProps } from "aem/types";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      position: "relative"
    },
    placeholder: {
      minWidth: 300
    },
    hidden: {
      display: "none !important"
    },
    showFirstChildOnly: {
      "& > *": {
        display: "none"
      },
      "& > *:first-child": {
        display: "block"
      }
    }
  });
});

export type EditableContainerProps = AEMContainerProps<HTMLDivElement> & {
  showContentInEditMode?: boolean;
  showFirstChildOnly?: boolean;
};

export const EditableContainer: React.FC<EditableContainerProps> = ({
  children,
  containerProps,
  placeholderComponent,
  showContentInEditMode,
  showFirstChildOnly
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-component-name="EditableContainer">
      <div {...containerProps}>
        <div
          className={clsx({
            [classes.hidden]: !showContentInEditMode,
            [classes.showFirstChildOnly]: showFirstChildOnly
          })}
        >
          {children}
        </div>
        <div className={classes.placeholder}>{placeholderComponent}</div>
      </div>
    </div>
  );
};

EditableContainer.defaultProps = {
  showContentInEditMode: true,
  showFirstChildOnly: false
};

export default EditableContainer;
