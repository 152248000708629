import { MapTo } from "@adobe/aem-react-editable-components";

import React, { FC } from "react";
import { useAppId } from "hooks-use-app-id";
import yn from "yn";
export type PropTypes = { [key: string]: any };

export interface WrapperOptions {
  type: string;
  superType?: string;
  overrideIntersectionObserver?: boolean;
  config: {
    dragDropName?: string;
    emptyLabel?: string;
    isEmpty?: (props: PropTypes) => boolean;
  };
  title?: string;
  group?: string;
  isContainer?: boolean;
}

export interface WrapperControlOptions {
  wrapper?: (Component: React.FC<any>) => React.ComponentType<any>;
  wrapperProps?: React.Props<unknown>;
}

export default (
  { type, config }: WrapperOptions,
  Component: any,
  { wrapper, wrapperProps }: WrapperControlOptions = {}
) => {
  const Abstract: FC<PropTypes> = props => {
    const { ariaLabel, ...rest } = props;
    const ariaProps = ariaLabel ? { "aria-label": ariaLabel } : {};
    const componentProps = { ...ariaProps, ...rest };
    const { isConcierge } = useAppId();
    const displayIfConcierge = props => {
      const hideConcierge =
        (yn(props.conciergeOnly) && !isConcierge) ||
        (yn(props.hideConcierge) && isConcierge);
      return !hideConcierge;
    };
    return (
      <React.Fragment>
        {displayIfConcierge(props) && (
          <Component
            conciergeOnly={isConcierge}
            hideConcierge={!isConcierge}
            {...componentProps}
            isVisible={true}
          />
        )}
      </React.Fragment>
    );
  };
  if (wrapper && wrapperProps) {
    //@ts-ignore
    wrapper = wrapper.bind(window, Abstract, wrapperProps);
  }
  const wrappedComponent = wrapper ? wrapper(Abstract) : null;
  // @ts-ignore
  return MapTo(type)(wrapper ? wrappedComponent : Abstract, config);
};
